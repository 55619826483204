.callout {
    z-index: 5;
    position: fixed;
    top: 0px;
    height: 50px;
    width: 100%;
    left: 0;
    background-color: yellow;
  
  }

#navbar {
    top: 50px;
}
  
.main {
    padding-top: 100px; /* Add a top margin to avoid content overlay */
    z-index: -1;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 585px) {
    #navbar {
      top: 60px;
    }
    .callout {
      height: 60px;
    }
  }
  
  @media screen and (max-width: 350px) {
    #navbar {
      top: 75px;
    }
    .callout {
      height: 75px;
    }
  }
    
  
  
  .calloutHeadline {
    color: black;
    font-weight: bold;
    line-height: 9pt;
    padding: 0;
    margin: 4pt;
    width: 100%;
    text-align: center;
  }
  
  .calloutSubheading {
    color: blue;
    line-height: 9pt;
    padding: 0;
    margin: 3pt;
    width: 100%;
    text-align: center;
  }