/* CSS files add styling rules to your content */

body {
  font-family: courier, arial, sans-serif;
  margin: 2em 2em 10em 2em;
  //padding-bottom: 50px;
}

#mastodon {
  display: none;
}

p.description {
  margin-bottom: 40px;
}

#navbar {
  overflow: visible;
  background: rgb(66, 66, 74);
  background: linear-gradient(
    184deg,
    rgba(66, 66, 74, 1) 0%,
    rgba(78, 78, 82, 1) 40%,
    rgba(24, 24, 24, 1) 100%
  );
  position: fixed; /* Set the navbar to fixed position */
  top: 0px; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  padding: 10px;
  left: 0px;
  z-index: 4;
}



h6 {
  color: #666666;
  font-weight: normal;
  display: block;
}

h1 {
  display: block;
  font-family: courier;
  font-style: bold;
  color: #1A005A;
  padding: 0px;
  margin: 0px;
}



h3 {
  line-height: 14px;
  position: relative;
  top: -15px;
}

h4.earlyProjects {
  margin: 10px 0px 3px 0px;
  font-size: 18pt;
  line-height: 14px;
}

.main {
  padding-top: 60px; /* Add a top margin to avoid content overlay */
  z-index: -1;
  margin-bottom: 20px;
}

.logoNav {
  position: relative;
  top: 0px;
  width: 45px;
}

.logoNav:hover {
  filter: brightness(125%);
  filter: drop-shadow(0px 3px 2px #000000);
  position: local;
  top: -3px;
}


ul.nav {
  list-style-type: none;
  margin: 0;
  padding: 0px 5px 0px 5px;
}

ul.nav li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  margin: 0px 3px 0px 3px;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #111;
}

ul.nav li.projects {
  float: right;
  margin-right: 10px;
}

.footer {
  position: fixed;
  font-size: 8pt;
  left: 0;
  bottom: 0;
  width: 100%;
  color: silver;
  text-align: center;
  background-color: white;
  height: 20px;
  padding-top: 5px;
  z-index: 1000;
}

.dateline {
  line-height: 10pt;
  margin: 0px;
}

div.article {
  position: relative;
  background: solid;
  background-color: #ebebeb;
  width: 50%;
  min-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  z-index: 0;
}

div.article:hover {
  transform: translate(0px, -3px);
  transition-duration: 0.2s;
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.4);
}

div.content {
  background-color: #f5f5f0;
  position: relative;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  z-index: 0;
}

button.article {
  width: 90px;
  height: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-width: 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

button {
  border-width: 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

button.download {
  width: 200px;
  height: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-width: 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

button:hover {
  background-color: white;
}

button.back {
  width: 90px;
  height: 40px;
  position: absolute;
  top: 80px;
  left: 10px;
  margin-bottom: 5px;
  border-width: 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

@media screen and (max-width: 675px) {
  button.back {
    position: relative;
    top: 0px;
    left: 0px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    height: 25px;
  }
  div.content {
    width: 100%;
    position: relative;
    left: -13px;
  }
}

button.back:hover {
  background-color: white;
}



.twitter {
  margin-left: auto;
  margin-right: auto;
  padding-left: auto;
  padding-right: auto;
}

mark.spoiler {
  background-color: black;
}

mark.spoiler:hover {
  background-color: silver;
}


a {
  z-index: 10000000;
}