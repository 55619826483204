.blog-post {
      position: relative;
  background: solid;
  background-color: #ebebeb;
  width: 50%;
  min-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  z-index: 0;
}

div.blog-post:hover {
    transform: translate(0px, -3px);
    transition-duration: 0.2s;
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.4);
  }

.buttonSpacer {
    height: 25px;
}

h2 {
    font-size: 18pt;
  }
  
  h2.post-title {
    margin: 0;
  }
  
  h6 {
    color: #666666;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
  
  table {
    position: relative;
    top: 0;
    /*border: 2px solid #171717;*/
  }
  
  td {
    vertical-align: center;
    padding: 2px;
    font-size: 12pt;
  }
  
  td.title {
    width: 100%;
  }
  
  table.byline {
    min-width: 180px;
    text-align: right;
  }

img.blogimg {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px 5px 5px 5px;
    border-collapse: separate;
}
@media screen and (max-width: 600px) {
    div.blog-post {
      width: 100%;
      min-width: initial;
      margin: 3px;
      position: relative;
      left: -13px;
      margin-bottom: 5px;
    }
    div.blog-post:hover {
      transform: translate(0px, -4px);
      transform: rotate(0);
      transition-duration: 0.2s;
    }
    img.blogimg {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 535px) {
    tr.mainRow { display: block; float: left; }
    td.title, td.byline { display: block; }
    td.byline {
        direction: rtl;
        float: left;
    }
  }