.audio-container {
  display: grid;
  grid-template-columns: 150px auto;
}

.audio-item {
  height: 130px;
  margin: 0;
  padding: 10px;
  grid-column: 2 / span 2;
}

.odd {
  background-color: #dbdbdb;
}

.even {
  background-color: #e6e6e6;
}

.cover {
  padding: 0;
  background-color: blue;
  grid-column: 1;
  width: 150px;
  margin: 0px;
}

.credits {
  width: 100%;
  word-wrap: break-word;
    display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

span.credits {
  font-style: italic;
  font-size: 10pt;
}
span.title {
  font-weight: bold;
  font-size: 15pt;
  overflow: hidden;
}

@media only screen and (max-width: 535px) {
  .cover {
    display: none;
  }

  .audio-container {
    width: 100%;
  }

  .audio-item {
    grid-column: 1 / span 3;
    height: auto;
  }
}

@media only screen and (max-width: 681px) {
  .oversize {
    height: auto;
  }
  img.oversize {
    height: auto;
  }
}

audio {
  width: 100%;
  max-width: 800px;
  margin: 5px 0px 5px 0px;
}
div:target {
  background-color: rgba(25, 197, 255, 0.25);
}

.audio-container:has(div:target) {
  scroll-margin-top: 2000px;
}

.audio-container:has(div:target):before {
  scroll-margin-top: 2000px;
  content: " ";
  display: block;
  height: 900px;
  margin: -900px 0 0;
    background-color: rgba(25, 197, 255, 0.25);
}